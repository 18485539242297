import React from "react";
import { Grid, Typography, List, ListItem, Link } from "@mui/material";
import "./App.css";

const DashboardThumbnails = [
  {
    name: "Use Case: Parkraummanagement",
    url: "https://dashboards.landshut-digitalerzwilling.arup.com/d/ac6d27a3-0076-4572-a1b0-c337392fe096/use-case-1-parkraummanagement?orgId=1",
    thumbnail: "UC_Parkraummanagement_AKTUELL_small.png",
  },

  {
    name: "Use Case: Aufenthaltsqualität",
    url: "https://dashboards.landshut-digitalerzwilling.arup.com/d/af49f936-4c37-4833-92f6-68d2d1ea19bf/use-case-2-aufenthaltsqualitat?orgId=1",
    thumbnail: "UC_Aufenthaltsqualität_AKTUELL_small.png",
  },
  {
    name: "Use Case: Auswirkung von Begrünungsmaßnahmen",
    url: "https://dashboards.landshut-digitalerzwilling.arup.com/d/b8445cd6-3872-4145-809a-e24caa2af52f/use-case-3-auswirkung-von-begruenungsmassnahmen?orgId=1",
    thumbnail: "UC_Begruenung_small.png",
  },
  {
    name: "Use Case: Leerstand",
    url: "https://dashboards.landshut-digitalerzwilling.arup.com/d/c0014c42-6c25-44af-ad5f-c56e4155abd1/use-case-4-leerstand?orgId=1",
    thumbnail: "UC_Leerstand_AKTUELL_small.png",
  },
  {
    name: "Use Case: Gegenüberstellung und Vergleich",
    url: "https://dashboards.landshut-digitalerzwilling.arup.com/d/LRjACU34k/use-case-gegenueberstellung-und-vergleich?from=now-6h&to=now&var-empty_buildings=All&var-to_be_empty=All&var-reuse_agreed=All&var-area_size=All&orgId=1",
    thumbnail: "UC_Gegenueberstellung-und-Vergleich_AKTUELL_small.png",
  },
];

const DashboardPreview = ({ name, url, thumbnail }: any) => (
  <Link href={url} target="_blank" rel="noopener noreferrer" underline="none">
    <div className="dashboard-preview">
      <img src={thumbnail} alt={name} />
      <h3>{name}</h3>
    </div>
  </Link>
);

const App = () => (
  <div className="App" style={{ transform: "scale(0.9)" }}>
    <nav>
      <List component="ul" sx={{ display: "flex", backgroundColor: "white" }}>
        <ListItem
          component="a"
          sx={{ cursor: "pointer" }}
          href="https://www.landshut.de/"
          target="_blank"
        >
          <Typography variant="h6" component="h1" sx={{ flexGrow: 1 }}>
            <img src="logo.png" alt="Stadt Traunreut" className="logo" />
          </Typography>
        </ListItem>
        <ListItem
          component="a"
          sx={{ cursor: "pointer" }}
          href="https://dashboards.landshut-digitalerzwilling.arup.com/d/ac6d27a3-0076-4572-a1b0-c337392fe096/use-case-1-parkraummanagement?orgId=1"
          target="_blank"
        >
          <div
            className="color-rectangle"
            style={{ backgroundColor: "#d41a31" }}
          />
          Parkraummanagement
        </ListItem>

        <ListItem
          component="a"
          sx={{ cursor: "pointer" }}
          href="https://dashboards.landshut-digitalerzwilling.arup.com/d/af49f936-4c37-4833-92f6-68d2d1ea19bf/use-case-2-aufenthaltsqualitat?orgId=1"
          target="_blank"
        >
          <div
            className="color-rectangle"
            style={{ backgroundColor: "#ea6738" }}
          />
          Aufenthaltsqualität
        </ListItem>
        <ListItem
          component="a"
          sx={{ cursor: "pointer" }}
          href="https://dashboards.landshut-digitalerzwilling.arup.com/d/b8445cd6-3872-4145-809a-e24caa2af52f/use-case-3-auswirkung-von-begruenungsmassnahmen?orgId=1"
          target="_blank"
        >
          <div
            className="color-rectangle"
            style={{ backgroundColor: "#60a25b" }}
          />
          Auswirkung von Begrünungsmaßnahmen
        </ListItem>
        <ListItem
          component="a"
          sx={{ cursor: "pointer" }}
          href="https://dashboards.landshut-digitalerzwilling.arup.com/d/c0014c42-6c25-44af-ad5f-c56e4155abd1/use-case-4-leerstand?orgId=1"
          target="_blank"
        >
          <div
            className="color-rectangle"
            style={{ backgroundColor: "#009fb0" }}
          />
          Leerstand
        </ListItem>
        <ListItem
          component="a"
          sx={{ cursor: "pointer" }}
          href="https://dashboards.landshut-digitalerzwilling.arup.com/d/LRjACU34k/use-case-gegenueberstellung-und-vergleich?from=now-6h&to=now&var-empty_buildings=All&var-to_be_empty=All&var-reuse_agreed=All&var-area_size=All&orgId=1"
          target="_blank"
        >
          <div
            className="color-rectangle"
            style={{ backgroundColor: "#efca45" }}
          />
          Gegenüberstellung und Vergleich
        </ListItem>
        <ListItem>
          <a
            href="https://www.esf.de/portal/DE/REACT-EU/inhalt.html"
            target="_blank"
            className="react-eu-logo-container"
            rel="noreferrer"
          >
            <img src="REACT-EU-Logo_smaller.png" alt="EU-React_logo" />
          </a>
        </ListItem>
      </List>
    </nav>
    <main>
      <Grid container spacing={2}>
        {DashboardThumbnails.map((dashboard, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={dashboard.name}>
            <div
              className={`dashboard-preview-container${
                index > 2 ? " center" : ""
              }`}
            >
              <DashboardPreview {...dashboard} />
            </div>
          </Grid>
        ))}
      </Grid>
      <List component="ul" sx={{ display: "flex", backgroundColor: "white" }}>
        <ListItem component={Link} href="/impressum" sx={{ cursor: "pointer" }}>
          Impressum
        </ListItem>
      </List>
    </main>
  </div>
);

export default App;
