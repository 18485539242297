import React from "react";

const Impressum = () => {
  return (
    <div style={{ marginLeft: "30px" }}>
      <h1>Impressum</h1>
      Die Inhalte dieser Seite werden im Auftrag der Stadt Landshut
      bereitgestellt durch:
      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>
        Arup Deutschland GmbH
        <br />
        Joachimsthaler Stra&szlig;e 41
        <br />
        10623 Berlin
      </p>
      <p>
        Handelsregister: HRB 141345 B<br />
        Registergericht: Amtsgericht Charlottenburg (Berlin)
      </p>
      <p>
        <strong>Vertreten durch:</strong>
        <br />
        Hinkers, Eva
        <br />
        Coughlan, Paul
        <br />
        Hoevels, Christopher
      </p>
      <h2>Kontakt</h2>
      <p>
        Telefon: +49 (0) 30 8859100
        <br />
        E-Mail: berlin@arup.com
      </p>
      <h2>Umsatzsteuer-ID</h2>
      <p>
        Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
        Umsatzsteuergesetz:
        <br />
        DE283001151
      </p>
      <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
      <p>
        <strong>Name und Sitz des Versicherers:</strong>
        <br />
        LOCKTON COMPANIES LLP
        <br />
        GLOBAL PROFESSIONAL &amp; FINANCIAL RISKS
        <br />
        The St Botolph Building, 138 Houndsditch, London EC3A 7AG
        <br />
        DX 765 London / City
      </p>
      <h2>
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
      </h2>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </div>
  );
};

export default Impressum;
